import { template as template_bf201d4dc84e4f5d951ace125046a790 } from "@ember/template-compiler";
const FKLabel = template_bf201d4dc84e4f5d951ace125046a790(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
